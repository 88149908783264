import create from 'zustand';
import { firstDayOfWeek, lastDayInCalendarView } from '@/utils/format-date';

interface MealPlanRangeState {
  startDate: Date;
  endDate: Date;
  updateRange: (startDate: Date, endDate?: Date) => void;
}

export const useMealPlanRangeStore = create<MealPlanRangeState>((set) => {
  return {
    startDate: firstDayOfWeek(new Date()),
    endDate: lastDayInCalendarView(new Date()),
    updateRange: (startDate, endDate) =>
      set({ startDate, endDate: endDate || lastDayInCalendarView(startDate) }),
  };
});
