import { post } from '@/services/httpClient';
import { formatDate } from '@/utils/format-date';
import addRecipeToDinner from '@/services/add-recipe-to-dinner';

export type AddRecipeToDateRequest = {
  householdId: number;
  profileId: number;
  standingShoppingTrip: string;
  dinnerDate: Date;
  recipeId: number;
};
const addRecipeToDate = async (
  request: AddRecipeToDateRequest,
): Promise<any> => {
  const dinner = await post(`api/v1/household_dinner`, {
    household: `/api/v1/household/${request.householdId}`,
    profile: `/api/v1/household_profile/${request.profileId}`,
    dinner_date: formatDate(request.dinnerDate),
    shopping_trip: request.standingShoppingTrip,
    status_code: 'A',
  });

  return addRecipeToDinner({
    recipeId: request.recipeId,
    isMainDish: false,
    dinnerId: dinner.id,
    householdId: request.householdId,
  });
};

export default addRecipeToDate;
