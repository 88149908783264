import { Box } from '@/components/base/box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faInbox } from '@fortawesome/free-solid-svg-icons';
import { ItalicText, Text } from '@/components/base/text';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@/components/base/flex';
import { theme } from '@/theme/index';
import { TextLink } from '@/components/base/text-link';
import { Button } from '@/components/base/buttons';

import { SavedRecipesList } from '@/components/recipes/RecipesSearchFavoriteTab/SavedRecipesList';

import { Form, Formik } from 'formik';
import { FormikTextInputField } from '@/components/base/formik/FormikTextInputField';
import { SavedRecipe } from '@/hooks/use-favorite-recipes';
import { RecipeRatingDetails } from '@/hooks/use-user-recipe-ratings';
import { FavoritesSearchForm } from './FavoritesSearchForm';
import { debounce } from 'lodash';
import { Center } from '@/components/base/center';
import { InfiniteLoaderControl } from '../InfiniteLoaderControl';

const StyledMessage = styled(Flex)`
  padding: ${theme.space.s}px;
  margin-top: 64px;
  margin-bottom: 64px;
  color: ${theme.colors.recipeSearchFavoriteTabMessageText};
`;

const StyledMessageTitle = styled(ItalicText)`
  font-size: ${theme.fontSizes.l};
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.recipeSearchFavoriteTabMessageTitle};
`;

const StyledNoResultsFoundMessageTitle = styled(ItalicText)`
  font-size: ${theme.fontSizes.l};
  color: ${theme.colors.recipeSearchFavoriteTabMessageTitle};
`;

const StyleMessageHeartIcon = styled(Box)`
  position: absolute;
  font-size: 250px;
  color: rgba(237, 237, 237, 0.5);
  top: -60px;
  left: 0;
  z-index: ${theme.zIndices.behind};

  ${({ theme }) => theme.mediaQueries.l} {
    left: -250px;
  }
`;

const StyledMailLink = styled(TextLink)`
  text-decoration: underline;
  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes['2xs']};
  }
`;

type FavoritesTabContentProps = {
  items: SavedRecipe[];
  recipesRatings?: RecipeRatingDetails[];
  showSelectMealDateOption?: boolean;
  onToggleFavoriteRecipe?: (recipeId: number, savedRecipeId?: number) => void;
  onAddRecipe?: (recipeId: number, date?: Date) => void;
  onRateRecipe?: (
    newValue: number,
    recipeId: number,
    recipeRatingId?: number,
  ) => void;
  onClearRating?: (recipeRatingId: number) => void;
  itemsStatus: 'error' | 'idle' | 'loading' | 'success';
  itemsError: unknown;
  totalCount: number | undefined;

  /**
   * if true, more recipes can be loaded
   */
  hasMoreRecipes: boolean;

  /**
   * If true, query engine is fetching more favorite recipes
   */
  isLoadingMore: boolean;
  /**
   * Callback when more recipes need to be loaded from the backend
   * @returns
   */
  onLoadMore?: () => unknown;

  searchText: string;
  setSearchText: (value: string) => unknown;
};

export const RecipesFavoritesTabContent = ({
  items,
  recipesRatings,
  showSelectMealDateOption = false,
  onToggleFavoriteRecipe = () => {},
  onAddRecipe = () => {},
  onRateRecipe = () => {},
  onClearRating = () => {},
  itemsStatus,
  itemsError,
  isLoadingMore,
  hasMoreRecipes,
  onLoadMore = async () => {},
  totalCount,
  searchText,
  setSearchText,
}: FavoritesTabContentProps) => {
  const filteredItems = items;

  let content: ReactElement | undefined = undefined;
  if (itemsStatus === 'loading') {
    content = (
      <StyledMessage justifyContent={'center'} alignItems={'center'}>
        <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
          <StyleMessageHeartIcon>
            <FontAwesomeIcon icon={faHeart} />
          </StyleMessageHeartIcon>

          <StyledMessageTitle marginBottom={'xl'}>
            Your Favorites Box is loading
          </StyledMessageTitle>
        </Box>
      </StyledMessage>
    );
  } else if (itemsStatus == 'error') {
    content = (
      <StyledMessage justifyContent={'center'} alignItems={'center'}>
        <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
          <StyleMessageHeartIcon>
            <FontAwesomeIcon icon={faHeart} />
          </StyleMessageHeartIcon>

          <StyledMessageTitle marginBottom={'xl'}>
            An Error Occurred
          </StyledMessageTitle>

          <Text marginBottom={'s'}>
            If this error persists, feel free to message us in the chat box to
            see if we can help!
          </Text>
        </Box>
      </StyledMessage>
    );
  } else if (totalCount === 0) {
    content = (
      <StyledMessage justifyContent={'center'} alignItems={'center'}>
        <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
          <StyleMessageHeartIcon>
            <FontAwesomeIcon icon={faHeart} />
          </StyleMessageHeartIcon>

          <StyledMessageTitle marginBottom={'xl'}>
            Your Favorites Box is currently empty
          </StyledMessageTitle>

          <Text marginBottom={'s'}>
            Add dishes to your Favorites from the Recipe Search tab above or add
            a dish to your Favorites from any recipe page by rating it 5 stars.
          </Text>

          <Text marginBottom={'s'}>
            If you have your own recipes that you would like added to your
            Favorites, send them to{' '}
            <StyledMailLink
              href="mailto:recipes@dinnertime.com"
              color={'linkDarkGreen'}
            >
              recipes@dinnertime.com
            </StyledMailLink>{' '}
            and we&apos;ll add them for you.
          </Text>

          <Text marginBottom={'s'}>
            Please include ingredients, instructions, recipe source, and a
            photo, if available.
          </Text>
        </Box>
      </StyledMessage>
    );
  } else if (filteredItems.length === 0) {
    content = (
      <StyledMessage justifyContent={'center'} alignItems={'center'}>
        <Box maxWidth={'500px'} textAlign={'center'} position={'relative'}>
          <StyleMessageHeartIcon>
            <FontAwesomeIcon icon={faInbox} />
          </StyleMessageHeartIcon>

          <StyledNoResultsFoundMessageTitle marginBottom={'xl'}>
            We didn&apos;t find matching recipes in your Favorites
          </StyledNoResultsFoundMessageTitle>

          <ItalicText marginBottom={'s'} variant={'default'}>
            Please try again, or use the Recipe Search Tab to search the entire
            library
          </ItalicText>
        </Box>
      </StyledMessage>
    );
  } else if (filteredItems.length > 0) {
    let infiniteLoaderContent = (
      <InfiniteLoaderControl
        fetchNextPage={onLoadMore as any}
        hasNextPage={hasMoreRecipes}
        isFetchingNextPage={isLoadingMore}
      />
    );

    content = (
      <>
        <SavedRecipesList
          items={filteredItems}
          favoriteItems={items}
          recipesRatings={recipesRatings}
          showSelectMealDateOption={showSelectMealDateOption}
          onToggleFavoriteRecipe={onToggleFavoriteRecipe}
          onAddRecipe={onAddRecipe}
          onRateRecipe={onRateRecipe}
          onClearRating={onClearRating}
        />
        {infiniteLoaderContent ? (
          <Center>{infiniteLoaderContent}</Center>
        ) : undefined}
      </>
    );
  }

  return (
    <>
      <FavoritesSearchForm
        value={searchText}
        onSetValue={setSearchText}
      ></FavoritesSearchForm>
      {content}
    </>
  );
};
