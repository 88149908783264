import { IngredientFilter } from '@/hooks/use-search-recipes';
import { logger } from '@/services/logging';
import { sliceOf } from '@/utils/stores';
import { StoryContext } from '@storybook/react';
import create, { StateCreator } from 'zustand';
import { devtools, combine } from 'zustand/middleware';

export interface RecipeBoxSearchOptions {
  keyword?: string;
  /**
   * list of ingredients (referred by rec_engine_id) to filter search by
   */
  ingredients?: IngredientFilter[];
  defaultTabSelected?: number;
  dinnerDate?: Date;
}
interface MealPlanCalendarState {
  moveActionTriggeredBy: number | null;
  setMoveActionTriggeredBy: (triggeredBy: number | null) => void;
  refetchDinners: boolean | null;
  setRefetchDinners: (refresh: boolean | null) => void;
  recipeBoxSearch: RecipeBoxSearchOptions | null;
  setRecipeBoxSearch: (search: RecipeBoxSearchOptions | null) => void;
  setRecipeBoxIngredients: (ingredients: IngredientFilter[]) => void;
  newShoppingTripDates: Date[] | null;
  setNewShoppingTripDates: (dates: Date[] | null) => void;

  /**
   * cleanup logic for temporary state like openForShoppingDate and search
   * by ingredient
   * @returns
   */
  onCloseRecipeBox: () => void;
}

export const createMealPlanCalendarSlice = sliceOf<MealPlanCalendarState>(
  (set) => {
    return {
      moveActionTriggeredBy: null,
      setMoveActionTriggeredBy: (triggeredBy) =>
        set({ moveActionTriggeredBy: triggeredBy }),
      refetchDinners: false,
      setRefetchDinners: (refresh) => set({ refetchDinners: refresh }),
      recipeBoxSearch: null,
      setRecipeBoxSearch: (search) => set({ recipeBoxSearch: search }),
      setRecipeBoxIngredients: (ingredients) =>
        set((state) => ({
          recipeBoxSearch: {
            ...state.recipeBoxSearch,
            ingredients: ingredients,
          },
        })),
      newShoppingTripDates: null,
      setNewShoppingTripDates: (dates) => set({ newShoppingTripDates: dates }),
      onCloseRecipeBox: () => {
        set((state) => {
          return {
            recipeBoxSearch: {
              ...state.recipeBoxSearch,
              dinnerDate: undefined,
              ingredients: [],
            },
          };
        });
      },
    };
  },
);

function debugMiddleware<T>(
  creator: StateCreator<T>,
  options: { enabled?: boolean },
): StateCreator<T> {
  const enabled = options.enabled ?? false;

  if (enabled) {
    return (set, getStore, ...rest) => {
      return creator(
        (...args) => {
          logger.info('updating state %s', args);
          set(...args);
        },
        getStore,
        ...rest,
      );
    };
  } else {
    return creator;
  }
}

export const useMealPlanCalendarStore = create<MealPlanCalendarState>(
  debugMiddleware(createMealPlanCalendarSlice, { enabled: false }),
);
