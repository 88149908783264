import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  faAngleLeft,
  faArrowUpRightFromSquare,
  faPlusCircle,
  faArrowRightRotate,
  faTimes,
  faClock,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from '@/components/base/image';
import { Box } from '@/components/base/box';
import { Flex } from '@/components/base/flex';

import { Dish, useHouseholdDinner } from '@/hooks/use-household-dinner';
import { Modal, ModalContent, ModalOverlay } from '@/components/base/modal';
import { TextLink } from '@/components/base/text-link';
import { ItalicText, Text } from '@/components/base/text';
import { formatDate, getTodayDate, isSameDate } from '@/utils/format-date';
import { NumberInput } from '@/components/base/number-input';
import {
  formatNumber2Decimal,
  formatNumberWithoutDecimal,
} from '@/utils/format-number';
import { RecipesSearchFavoriteTab } from '@/components/recipes';
import { Slide } from '@/components/base/slide';
import { useHousehold } from '@/hooks/use-household';
import useCommandRequest from '@/hooks/use-command-request';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';
import addRecipeToDinner from '@/services/add-recipe-to-dinner';
import { useScoreCardEvent } from '@/hooks/use-score-card-event';
import replaceDish from '@/services/replace-dish';
import deleteDish from '@/services/delete-dish';
import suggestDish from '@/services/suggest-dish';
import { SearchRecipeHeader } from '@/components/meal-planner/SearchRecipeHeader';
import setServingsForDish from '@/services/set-servings-for-dish';
import { useHouseholdProfiles } from '@/hooks/use-household-profiles';
import addRecipeToDate from '@/services/add-recipe-to-date';
import { IngredientFilter } from '@/hooks/use-search-recipes';

const StyledModalContent = styled(ModalContent)`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.m} {
    max-height: 100%;
    height: inherit;
    max-width: 100%;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 1200px;
  }
`;

const StyledHeader = styled(Flex)`
  min-height: 20px;
  padding: ${({ theme }) => theme.space.s}px;
  background: ${({ theme }) => theme.colors.dinnerRecipeBoxBackgroundColor};
`;

const StyledLeftArrowLink = styled(TextLink)`
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
  width: 1.5em;
  height: 1.5em;
  border-radius: ${({ theme }) => theme.radii['50%']};
  padding: ${({ theme }) => theme.space['3xs']}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const StyledTitle = styled(Text)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['s']};
  margin-right: ${({ theme }) => theme.space.s}px;
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxTitleColor};
`;

const StyledItalicText = styled(ItalicText)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxTitleItalicColor};
`;

const StyledViewAllRecipesTextLink = styled(TextLink)`
  font-style: italic;
  display: inline-block;
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes['2xs']};
  }
`;

const StyledDinnerWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.dinnerRecipeBoxBackgroundColor};
  padding: ${({ theme }) => theme.space.s}px;
  padding-bottom: ${({ theme }) => theme.space['3xl']}px;
`;

const DISH_WIDTH = 140;
const DISH_HEIGHT = 140;

const StyledSuggestRecipeWrapper = styled(Flex)`
  position: relative;
  background-color: rgba(255, 255, 255, 0.35);
  width: ${DISH_WIDTH}px;
  height: ${DISH_HEIGHT}px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    transition: all 0.5s ease-in-out;
  }
`;

const StyledSuggestRecipeIconWrapper = styled(Flex)`
  cursor: pointer;
  margin-top: ${({ theme }) => theme.space.l}px;
  margin-bottom: ${({ theme }) => theme.space.m}px;
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space['3xs']}px;
  border-radius: ${({ theme }) => theme.radii['50%']};
  width: 64px;
  height: 64px;
  align-items: center;
  justify-content: center;
  font-size: 60px;

  & .fa-circle-plus {
    color: ${({ theme }) => theme.colors.primaryLight200};
    border-radius: ${({ theme }) => theme.radii['50%']};
    padding: ${({ theme }) => theme.space['3xs']};
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const StyledSuggestRecipeTitle = styled(Text)`
  background: ${({ theme }) => theme.colors.primaryLight200};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['5xs']};
  width: ${DISH_WIDTH}px;
  text-align: center;
`;

const StyledRecipeStateName = styled(ItalicText)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
`;

const StyledRecipeStateValue = styled(Text)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
`;

const StyledRecipeStats = styled(Box)`
  margin-left: ${({ theme }) => theme.space['xs']}px;
`;

const StyleRecipeStatsSeparator = styled(StyledRecipeStats)`
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxRecipeStatSeparatorColor};
  padding-right: ${({ theme }) => theme.space['3xs']}px;
  padding-left: ${({ theme }) => theme.space['3xs']}px;
`;

const StyledNutritionFact = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizes['4xs']};
  border-radius: ${({ theme }) => theme.radii['xs']};
  padding: ${({ theme }) => theme.space['xs']}px;
  background: ${({ theme }) =>
    theme.colors.dinnerRecipeBoxNutritionFactBackground};
  border: 1px solid
    ${({ theme }) => theme.colors.dinnerRecipeBoxNutritionFactBorder};
  flex-direction: column;
  flex: 1;
  white-space: nowrap;
  margin: 0 0.25em;
  text-align: center;
  color: ${({ theme }) => theme.colors.textGray20};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryLight200};
    background: white;
  }
`;

const StyledNutritionFactName = styled(Box)`
  text-align: center;
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSizes['6xs']};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

const StyledNutritionFactValue = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

type DinnerRecipeBoxModalProps = {
  isOpen: boolean;
  dinnerDate?: Date;
  onClose?: () => void;
  recipeSearchKeyword?: string;
  recipeSearchIngredients?: IngredientFilter[];
  defaultTabSelected?: number;
  onNewDate?: (date: Date) => void;
};

export const DinnerRecipeBoxModal = ({
  isOpen,
  dinnerDate,
  recipeSearchKeyword,
  recipeSearchIngredients,
  onClose = () => {},
  defaultTabSelected = 0,
  onNewDate = () => {},
}: DinnerRecipeBoxModalProps) => {
  const setIngredients = useMealPlanCalendarStore(
    (state) => state.setRecipeBoxIngredients,
  );
  const [showDishes, setShowDishes] = useState(!!dinnerDate);

  const { household } = useHousehold();

  const { dinners = [], refetch: refreshDinner = () => {} } =
    useHouseholdDinner(
      dinnerDate ?? getTodayDate(),
      dinnerDate ?? getTodayDate(),
    );

  const { defaultProfile } = useHouseholdProfiles();

  const dinner = dinnerDate
    ? dinners.find((d) => isSameDate(d.date, dinnerDate))
    : null;

  const activeTime = useMemo(
    () => dinner?.dishes.reduce((sum, dish) => sum + dish.recipe.prepTime, 0),
    [dinner],
  );

  const { send: sendAddRecipeToDinner } = useCommandRequest();

  const setRefetchDinners = useMealPlanCalendarStore(
    (state) => state?.setRefetchDinners,
  );

  const { sendScoreCardEvent } = useScoreCardEvent();

  const { isLoading: isSendSuggestNewDish, send: sendSuggestNewDish } =
    useCommandRequest();

  useEffect(() => {
    (async () => {
      if (isOpen && dinnerDate) {
        await sendScoreCardEvent('EDIT_MEAL_PLAN');
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    setShowDishes(!!dinnerDate);
  }, [dinnerDate]);

  if (!household) {
    return null;
  }

  const onAddRecipeToDinner = async (recipeId: number) => {
    if (!dinner) return;

    const result = await sendAddRecipeToDinner(() =>
      addRecipeToDinner({
        dinnerId: dinner.id,
        recipeId: recipeId,
        isMainDish: true,
        householdId: household.id,
      }),
    );

    if (result.completed) {
      setRefetchDinners(true);

      refreshDinner();
      await sendScoreCardEvent('ADD_SAVED_RECIPE');
    }
  };

  const onAddRecipeToAnotherDinner = async (
    recipeId: number,
    date: Date,
    dinnerId: number,
  ) => {
    const result = await sendAddRecipeToDinner(() =>
      addRecipeToDinner({
        dinnerId: dinnerId,
        recipeId: recipeId,
        isMainDish: true,
        householdId: household.id,
      }),
    );

    if (result.completed) {
      setRefetchDinners(true);
      onNewDate(date);
      await sendScoreCardEvent('ADD_SAVED_RECIPE');
    }
  };

  const onAddRecipeToAnotherDate = async (recipeId: number, date: Date) => {
    if (!household.standingShoppingTrip) return;
    if (!defaultProfile) return;

    const result = await sendAddRecipeToDinner(() =>
      addRecipeToDate({
        recipeId,
        dinnerDate: date,
        profileId: defaultProfile?.id,
        householdId: household.id,
        standingShoppingTrip: household.standingShoppingTrip ?? '',
      }),
    );

    if (result.completed) {
      setRefetchDinners(true);
      onNewDate(date);
      await sendScoreCardEvent('ADD_SAVED_RECIPE');
    }
  };

  const onSuggestNewDish = async () => {
    if (!dinner) return;

    const result = await sendSuggestNewDish(() =>
      suggestDish({
        householdId: household.id,
        householdDinnerId: dinner.id,
      }),
    );

    if (result.completed) {
      refreshDinner();
      setRefetchDinners(true);
    }
  };

  // runs prop-provided onClose, along with cleanup logic for state when modal closes
  const handleOnClose = () => {
    // we want to remove any implicit ingredient filters when the box closes
    setIngredients([]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={handleOnClose}
      modalPosition={'top'}
      maxHeight={'100%'}
    >
      <ModalOverlay />
      <StyledModalContent>
        {!dinner ? (
          <SearchRecipeHeader onClose={handleOnClose} />
        ) : (
          <>
            <StyledHeader
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <StyledLeftArrowLink
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  handleOnClose();
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} width={18} height={18} />
              </StyledLeftArrowLink>

              <Box>
                <StyledTitle
                  cursor={'pointer'}
                  onClick={() => setShowDishes(true)}
                >
                  Menu <StyledItalicText>for</StyledItalicText>{' '}
                  {formatDate(dinner.date, 'E, LLLL do')}
                </StyledTitle>

                {showDishes && (
                  <StyledViewAllRecipesTextLink
                    href={`/dinners/${formatDate(dinner.date, 'yyyy-MM-dd')}`}
                    variant={'2xs'}
                  >
                    View All Recipes{' '}
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      width={14}
                      height={14}
                    />
                  </StyledViewAllRecipesTextLink>
                )}
              </Box>

              <Box />
            </StyledHeader>

            <Slide>
              {showDishes && (
                <>
                  <StyledDinnerWrapper
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    overflow={'auto'}
                  >
                    {dinner?.dishes
                      .sort((dish1, dish2) => {
                        if (dish1.main) {
                          if (dish2.main) {
                            // this shouldn't happen in practice, but
                            // we'll include it for defensive coding
                            return dish1.id - dish2.id;
                          }
                          return -1;
                        } else if (dish2.main) {
                          return 1;
                        } else {
                          return dish1.id - dish2.id;
                        }
                      })
                      .map((dish) => (
                        <DisplayDish
                          key={dish.id}
                          dish={dish}
                          onReplacedDish={() => {
                            refreshDinner();
                            setRefetchDinners(true);
                          }}
                          onDeletedDish={() => {
                            refreshDinner();
                            setRefetchDinners(true);
                          }}
                          onServingsChange={() => {
                            refreshDinner();
                            setRefetchDinners(true);
                          }}
                        />
                      ))}

                    <Flex
                      flex={1}
                      alignItems={'center'}
                      justifyContent={'center'}
                      onClick={() => onSuggestNewDish()}
                    >
                      <StyledSuggestRecipeWrapper>
                        <StyledSuggestRecipeIconWrapper>
                          <FontAwesomeIcon
                            spinPulse={isSendSuggestNewDish}
                            icon={faPlusCircle}
                          />
                        </StyledSuggestRecipeIconWrapper>

                        <StyledSuggestRecipeTitle>
                          Suggest a Recipe
                        </StyledSuggestRecipeTitle>
                      </StyledSuggestRecipeWrapper>
                    </Flex>
                  </StyledDinnerWrapper>

                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingY={'2xs'}
                    paddingX={'l'}
                  >
                    <StyledRecipeStats>
                      <StyledRecipeStateName>Active</StyledRecipeStateName>
                      <StyledRecipeStateValue>
                        <FontAwesomeIcon
                          icon={faClock}
                          width={11}
                          height={11}
                        />{' '}
                        {activeTime} mins
                      </StyledRecipeStateValue>
                    </StyledRecipeStats>

                    <StyleRecipeStatsSeparator>|</StyleRecipeStatsSeparator>

                    <StyledRecipeStats>
                      <StyledRecipeStateName>Total</StyledRecipeStateName>
                      <StyledRecipeStateValue>
                        <FontAwesomeIcon
                          icon={faClock}
                          width={11}
                          height={11}
                        />{' '}
                        {dinner.totalTime} mins
                      </StyledRecipeStateValue>
                    </StyledRecipeStats>

                    <StyleRecipeStatsSeparator>|</StyleRecipeStatsSeparator>

                    <StyledRecipeStats>
                      <StyledRecipeStateName>Per Serving</StyledRecipeStateName>
                      <StyledRecipeStateValue>
                        $ {formatNumber2Decimal(dinner.pricePerServing, false)}
                      </StyledRecipeStateValue>
                    </StyledRecipeStats>
                  </Flex>

                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingBottom={'s'}
                    paddingX={'l'}
                    overflow={'auto'}
                  >
                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(dinner.caloriesPerServing)}
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>
                        Calories
                      </StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(dinner.proteinPerServing)} g
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>Protein</StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(
                          dinner.netCarbohydratesPerServing,
                        )}{' '}
                        g
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>
                        Net Carbs
                      </StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(dinner.fatPerServing)} g
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>
                        Total Fat
                      </StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(
                          dinner.saturatedFatPerServing,
                        )}{' '}
                        g
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>
                        Saturated Fat
                      </StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {formatNumberWithoutDecimal(dinner.sodiumPerServing)} mg
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>Sodium</StyledNutritionFactName>
                    </StyledNutritionFact>

                    <StyledNutritionFact>
                      <StyledNutritionFactValue>
                        {dinner.pointsPlusPerServing}
                      </StyledNutritionFactValue>
                      <StyledNutritionFactName>
                        SmartPoints®
                      </StyledNutritionFactName>
                    </StyledNutritionFact>
                  </Flex>
                </>
              )}
            </Slide>
          </>
        )}

        <RecipesSearchFavoriteTab
          householdId={household.id}
          defaultTabSelected={defaultTabSelected}
          showSelectMealDateOption={!dinnerDate}
          onTabSelected={() => {
            setShowDishes(false);
          }}
          onAddRecipeToDinner={async (recipeId, date, dinnerId) => {
            // TODO refactor these numerous nested callbacks to simplify the logic

            // when adding meal from meal planner dropdown, the date argument will be nil,
            // while the actual dinnerDate state variable will be set
            // date = date ?? dinnerDate;
            if (date && dinnerId) {
              await onAddRecipeToAnotherDinner(recipeId, date, dinnerId);
            } else if (date && !dinnerId) {
              await onAddRecipeToAnotherDate(recipeId, date);
            } else if (!date && !dinnerId) {
              await onAddRecipeToDinner(recipeId);
            }
          }}
          defaultIngredients={recipeSearchIngredients ?? []}
          defaultSearchKeyword={recipeSearchKeyword}
        />
      </StyledModalContent>
    </Modal>
  );
};

const StyledDish = styled(Flex)`
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
`;

const StyledDishImage = styled(Image)`
  width: ${DISH_WIDTH}px;
  height: ${DISH_HEIGHT}px;
  border-radius: ${({ theme }) => theme.radii['2xs']};
`;

const StyledDishTitleWrapper = styled(Box)`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 30px;
  width: ${DISH_WIDTH}px;
  text-align: center;
`;

const StyledDishTitleLink = styled(TextLink)`
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxDishTitleColor};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: ${({ theme }) => theme.lineHeights['8xs']};
  width: ${DISH_WIDTH}px;
  padding: 0 5px;
  ${({ theme }) => theme.mediaQueries.m} {
    font-size: ${({ theme }) => theme.fontSizes['4xs']};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledBottomActions = styled(Flex)`
  position: absolute;
  bottom: -16px;
  width: ${DISH_WIDTH}px;
  color: ${({ theme }) => theme.colors.dinnerRecipeBoxDishActionsColor};
  align-items: center;
  justify-content: space-between;
`;

const StyledBottomActionButton = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii['50%']};
  width: 38px;
  height: 38px;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: 0.25s;
    border: 1px solid
      ${({ theme }) => theme.colors.dinnerRecipeBoxLeftArrowColor};
  }
`;

const StyledBottomActionRemoveButton = styled(StyledBottomActionButton)`
  &:hover {
    color: ${({ theme }) =>
      theme.colors.dinnerRecipeBoxDishActionRemoveButtonHoverColor};
  }
`;

const StyledBottomActionServingsButton = styled(StyledBottomActionButton)`
  flex-direction: column;
`;

const StyledBottomActionServingsButtonInput = styled(NumberInput)`
  font-size: ${({ theme }) => theme.fontSizes['2xs']};
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  width: 34px;
  height: 30px;
  padding: 0;
  text-align: center;
  border-radius: ${({ theme }) => theme.radii['50%']};
  border: none;
  background: none;
  margin: 0 -${({ theme }) => theme.space.m}px 0 0;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

const StyledBottomActionServingsButtonInputLabel = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes['7xs']};
  line-height: 0;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin: -6px 0 ${({ theme }) => theme.space['3xs']}px 0;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) =>
    theme.colors.dinnerRecipeBoxBottomActionServingsButtonInputLabelBackground};
  padding: 6px 5px;
  border-radius: ${({ theme }) => theme.radii['2xs']};
`;

type DisplayDishProps = {
  dish: Dish;
  onReplacedDish?: () => void;
  onDeletedDish?: () => void;
  onServingsChange?: () => void;
};
const DisplayDish = ({
  dish,
  onReplacedDish = () => {},
  onDeletedDish = () => {},
  onServingsChange = () => {},
}: DisplayDishProps) => {
  const [servings, setServings] = useState<number>(dish.servings);

  const { isLoading: isSendReplaceDishLoading, send: sendReplaceDish } =
    useCommandRequest();

  const { isLoading: isSendDeleteDishLoading, send: sendDeleteDish } =
    useCommandRequest();

  const { send: sendSetServings } = useCommandRequest();

  const onReplaceDish = async (dishId: number) => {
    const result = await sendReplaceDish(() => replaceDish(dishId));
    if (result.completed) {
      onReplacedDish();
    }
  };

  const onDeleteDish = async (dishId: number) => {
    const result = await sendDeleteDish(() => deleteDish(dishId));
    if (result.completed) {
      onDeletedDish();
    }
  };

  return (
    <StyledDish key={dish.id}>
      <StyledDishImage alt={dish.recipe.title} src={dish.recipe.thumb} />

      <StyledDishTitleWrapper>
        <StyledDishTitleLink
          href={`/recipes/${dish.recipe.id}?servings=${servings}`}
          target={'_blank'}
        >
          {dish.recipe.title}
        </StyledDishTitleLink>
      </StyledDishTitleWrapper>

      <StyledBottomActions>
        <StyledBottomActionButton onClick={() => onReplaceDish(dish.id)}>
          <FontAwesomeIcon
            spinPulse={isSendReplaceDishLoading}
            icon={faArrowRightRotate}
            width={14}
            height={14}
          />
        </StyledBottomActionButton>

        <StyledBottomActionServingsButton>
          <StyledBottomActionServingsButtonInput
            name={`dish[${dish.id}].servings`}
            defaultValue={servings}
            value={servings}
            onChange={async (evt: any) => {
              const val = parseInt(evt.currentTarget.value);
              setServings(val);
              await sendSetServings(() => setServingsForDish(dish.id, val));
              onServingsChange();
            }}
          />
          <StyledBottomActionServingsButtonInputLabel>
            servings
          </StyledBottomActionServingsButtonInputLabel>
        </StyledBottomActionServingsButton>

        <StyledBottomActionRemoveButton onClick={() => onDeleteDish(dish.id)}>
          <FontAwesomeIcon
            spinPulse={isSendDeleteDishLoading}
            icon={faTimes}
            width={14}
            height={14}
          />
        </StyledBottomActionRemoveButton>
      </StyledBottomActions>
    </StyledDish>
  );
};
