/**
 * Internationalization/language formatting utilities
 *
 */

export interface PluralizeOptions {
  pluralRules?: Intl.PluralRules;
}

export class LangParseError extends Error {
  isLangParseError = true;
}

/**
 * Reads a string representing compound fraction (e.g. 1 1/2), and splits the whole number
 * and fraction components.
 *
 * Note: it does not parse either the whole or fraction into an actual number object
 * @param number
 * @returns
 */
export function parseCompoundFraction(number: string) {
  if (!number.includes('/')) {
    return null;
  }
  const pattern = /^(?:(\d+) ?)?(\d+\/\d+)?$/;
  const matchedCount = number.match(pattern);
  if (!matchedCount || matchedCount.length <= 2) {
    return null;
  }
  const whole =
    typeof matchedCount[1] === 'undefined' // will happen when it's a simple fraction
      ? 0
      : Number.parseInt(matchedCount[1], 10);
  if (Number.isNaN(whole)) {
    return null;
  }
  const fraction = parseFraction(matchedCount[2]);
  return { whole, fraction };
}

export function parseFraction(value: string) {
  const splitValue = value.split('/').map((x) => Number.parseInt(x, 10));
  if (splitValue.length < 2) {
    throw new LangParseError(`value '${value}' is not a fraction`);
  }
  const [num, denom] = splitValue;
  if (Number.isNaN(num) || Number.isNaN(denom)) {
    throw new LangParseError(`can't parse value "${value}" as a fraction`);
  }
  return { num, denom };
}

export function tryCompoundFractionToFloat(value: string) {
  const compountValue = parseCompoundFraction(value);
  if (!compountValue) {
    return null;
  }
  const whole = compountValue.whole;
  const { num, denom } = compountValue.fraction;
  const fractionPart = num / denom;
  return whole + fractionPart;
}

export function isCountPlural(
  count: string,
  { pluralRules = new Intl.PluralRules() }: PluralizeOptions = {},
) {
  const fromCompoundFraction = tryCompoundFractionToFloat(count);
  let result: boolean;
  if (typeof fromCompoundFraction === 'number') {
    result = !(fromCompoundFraction <= 1.0 && fromCompoundFraction > 0);
  } else {
    const countAsF = Number.parseFloat(count);
    const langCount = pluralRules.select(countAsF);
    result = langCount !== 'one' && !(countAsF < 1.0 && countAsF > 0);
  }
  return result;
}

export function pluralize(
  count: string,
  singularOutput: string,
  pluralOutput: string,
  { pluralRules = new Intl.PluralRules() }: PluralizeOptions = {},
) {
  const isPlural = isCountPlural(count);
  if (isPlural) {
    return pluralOutput;
  } else {
    return singularOutput;
  }
}

export function capitalizeWords(str: string) {
  return str.replace(/(?:^|\s)\S/g, (str) => str.toUpperCase());
}
/**
 * Format a field name from the api fieldname scheme
 * to a human-readable English name
 * @param fields
 * @returns
 */
export function formatFields(fields: string) {
  if (fields != null) {
    fields = fields.replace(/_f$/g, ''); // Remove trailing '_f'
    // fields = fields.replace(/_f(?=[_\s])/g, ', '); // Replace '_f_' or '_f' ,' with ', '
    fields = fields.replace(/_/g, ' '); // Replace '_' with ' '
    fields = capitalizeWords(fields);
    fields = fields.replace('Net Carbohydrates', 'Net Carbs');
  }
  return fields;
}
