import { post } from '@/services/httpClient';

export type AddRecipeToDinner = {
  householdId: number;
  dinnerId: number;
  recipeId: number;
  isMainDish: boolean;
};
const addRecipeToDinner = (request: AddRecipeToDinner): Promise<any> => {
  return post(`api/v1/household_dinner_dish`, {
    household: `/api/v1/household/${request.householdId}`,
    household_dinner: `/api/v1/household_dinner/${request.dinnerId}`,
    status_code: 'A',
    is_main_dish: request.isMainDish,
    recipe: `/api/v1/recipe/${request.recipeId}`,
  });
};

export default addRecipeToDinner;
